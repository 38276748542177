<template>
  <v-app>
    <v-container>
      <v-card>
        <v-card-title style="justify-content: space-between">
          СМС
        </v-card-title>
        <v-card-actions>
          <v-row>
            <v-col class="col-12 col-md-2">
              <v-chip color="#fff">
                Всего {{ Number(stats.totalCount) || 0 }} смс,
                {{ (stats.sum || 0) }}
              </v-chip>
            </v-col>
            <YearMonth @change="loadTable" v-model="filterdate"/>
            <v-col class="col-12 col-md-6">
              <v-text-field
                  outlined
                  v-model="search"
                  placeholder="Поиск..."
                  label=""
                  append-icon="mdi-magnify"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-actions>
        <v-card-text>
          <v-data-table
              hover
              :items="rows"
              :headers="headers"
              item-key="id"
              :search="search"
              :items-per-page="10"
              :loading="loading"
              :footer-props="{'items-per-page-options':[10]}"
              no-data-text="ЗП нет"
              loading-text="Загрузка..."
              mobile-breakpoint="0"
          >
            <template v-slot:item="{item}">
              <tr>
                <td style="white-space: nowrap">{{ item.phone }}</td>
                <td>{{ $root.dateToRus(item.datetimeObj.date) }} {{ item.datetimeObj.time }}</td>
                <td>{{ item.text }}</td>
                <td>{{ item.count }}</td>
                <td>{{ Number(item.success) ? 'Да' : 'Нет' }}</td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-container>
  </v-app>
</template>

<script>
import YearMonth from "../../components/YearMonth";
export default {
  name: "Sms",
  components: {YearMonth},
  data() {
    let years = [];
    for (let i = 0; i <= this.$moment().format('YYYY') - 2015; i++) {
      years.push((2015 + i).toString())
    }
    return {
      rows: [],
      stats: {},
      headers: [
        {text: 'Телефон', value: 'phone'},
        {text: 'Дата/время'},
        {text: 'Текст', value: 'text'},
        {text: 'Кол-во смс'},
        {text: 'Успешно отправлено'},
      ],
      search: '',
      loading: true,
      years,
      filterdate: [this.$moment().format('YYYY'), this.$moment().format('MM')],
    }
  },
  methods: {
    loadTable() {
      this.rows = [];
      this.loading = true;
      this.$store.state.server.request('cleaner/sms',{year: this.filterdate[0], month: this.filterdate[1]}, (data) => {
        this.loading = false
        this.rows = data.response
        this.stats = data.stats;
      }, () => {
        this.loading = false
      })
    },
  },
  mounted() {
    this.loadTable()
  }
}
</script>

<style scoped>

</style>
